import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import ContactMain from '../components/contactMain'
import OfficeDepartments from '../components/cardIconsOffices'
import CardCompaniesContacts from '../components/cardCompaniesContacts'

const Contact = ({ data, location }) => {
  const heroImage = data.heroImage
  const post = data.markdownRemark
  const btnId = (location.state != null && location.state.btn != null) ? location.state.btn : "all"

  return (
    <>
      <Layout>
        <div id="sub-page">          
          <Hero
            class="intro-65"
            image={heroImage.childImageSharp.gatsbyImageData}
            title="Need to get in touch?"
            subtitle="Fill out the form below and we'll get back to you."
            type="contact"
            alt="hand holding a paper plane ready to throw"
          />
        </div>

        <main>
            <ContactMain />

            <OfficeDepartments />

          {post.frontmatter.section.map((sections, index) => (
            <section className="bg-white" key={index}>
              <MDBContainer>
                <MDBRow>
                  <MDBCol md="12" className="pr-5 pb-5">
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-4">
                      {sections.title}
                      <span id="worldwide-contacts" className="anchor">
                        &nbsp;
                      </span>                                          
                    </h2>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>

              {sections.feature && (
                <CardCompaniesContacts data={sections.feature} btnId={btnId}/>
              )}
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </section>
          ))}
        </main>
      </Layout>
    </>
  )
}
export default Contact

export function Head({ data, location }) {
  const heroImage = data.heroImage
  return (
    <SEO
      title="Contact Us"
      description="Want more information on Firetide products and services? Please get in touch using the contact details or web form provided."
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query {
    markdownRemark(
      fields: {}
      frontmatter: { template: { eq: "other" }, name: { eq: "contact" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description
        }
        section {
          title
          subtitle
          description
          feature {
            title
            subtitle
            description
            tel
            email
            icon
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            link
            linktext
            placement  
          }
        }
        title
        subtitle
      }
      html
    }
    heroImage: file(name: { eq: "contact-us" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`
